export default [

  // *=============DASHBOARD=============
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/crm/dashboard/Dashboard.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'user-list',
    component: () => import('@/views/crm/user/users-list/UsersList.vue'),
    meta: {
      resource: 'user_edit',
      action: 'read',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/crm/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'user_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- MY SETTINGS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/my-settings',
    name: 'my-settings',
    component: () => import('@/views/crm/my-settings/users-edit/UsersEdit.vue'),
    meta: {
      resource: '*',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- CUSTOMER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/customers/list',
    name: 'customers-list',
    meta: {
      resource: 'm_customer',
      action: 'read',
    },
    component: () => import('@/views/crm/customer/customers-list/CustomerList.vue'),
  },
  {
    path: '/customers/edit/:id',
    name: 'customers-edit',
    meta: {
      resource: 'm_customer',
      action: 'read',
    },
    component: () => import('@/views/crm/customer/customers-edit/CustomersEdit.vue'),
  },
  // *===============================================---*
  // *--------- PRODUCT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/products/list',
    name: 'products-list',
    component: () => import('@/views/crm/product/products-list/ProductList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/products/edit/:id',
    name: 'products-edit',
    component: () => import('@/views/crm/product/products-edit/ProductsEdit.vue'),
    meta: {
      resource: 'product_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- order ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/orders/list',
    name: 'orders-list',
    component: () => import('@/views/crm/order/orders-list/OrderList.vue'),
    meta: {
      resource: 'm_order',
      action: 'read',
    },
  },
  {
    path: '/orders/add',
    name: 'orders-add',
    component: () => import('@/views/crm/order/orders-edit/OrdersEdit.vue'),
    meta: {
      resource: 'm_order',
      action: 'read',
    },
  },
  {
    path: '/orders/edit/:id',
    name: 'orders-edit',
    component: () => import('@/views/crm/order/orders-edit/OrdersEdit.vue'),
    meta: {
      resource: 'm_order',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Consignment ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/consignments/list',
    name: 'consignments-list',
    component: () => import('@/views/crm/consignment/consignments-list/ConsignmentList.vue'),
    meta: {
      resource: 'm_consignment',
      action: 'read',
    },
  },
  {
    path: '/consignments/add',
    name: 'consignments-add',
    component: () => import('@/views/crm/consignment/consignments-edit/ConsignmentEdit.vue'),
    meta: {
      resource: 'consignment_edit',
      action: 'read',
    },
  },
  {
    path: '/consignments/edit/:id',
    name: 'consignments-edit',
    component: () => import('@/views/crm/consignment/consignments-edit/ConsignmentEdit.vue'),
    meta: {
      resource: 'consignment_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Proposal ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/proposals/settings',
    name: 'proposals-settings',
    component: () => import('@/views/crm/proposal/settings/ProposalSettings.vue'),
    meta: {
      resource: 'm_proposal',
      action: 'read',
    },
  },
  {
    path: '/proposals/list',
    name: 'proposals-list',
    component: () => import('@/views/crm/proposal/proposals-list/ProposalList.vue'),
    meta: {
      resource: 'm_proposal',
      action: 'read',
    },
  },
  {
    path: '/proposals/add',
    name: 'proposals-add',
    component: () => import('@/views/crm/proposal/proposals-edit/ProposalsEdit.vue'),
    meta: {
      resource: 'm_proposal',
      action: 'read',
    },
  },
  {
    path: '/proposals/edit/:id',
    name: 'proposals-edit',
    component: () => import('@/views/crm/proposal/proposals-edit/ProposalsEdit.vue'),
    meta: {
      resource: 'm_proposal',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Mail ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/emails/categories',
    name: 'email-categories',
    component: () => import('@/views/crm/email/category-list/CategoryList.vue'),
    meta: {
      resource: 'm_email_category',
      action: 'read',
    },
  },
  {
    path: '/emails/address/:category_id',
    name: 'email-address',
    component: () => import('@/views/crm/email/email-address-list/EmailAddressList.vue'),
    meta: {
      resource: 'm_email_category',
      action: 'read',
    },
  },
  {
    path: '/emails/blacklist',
    name: 'email-blacklist',
    component: () => import('@/views/crm/email/email-blacklist/EmailAddressList.vue'),
    meta: {
      resource: 'm_email_category',
      action: 'read',
    },
  },
  {
    path: '/emails/send',
    name: 'email-send',
    component: () => import('@/views/crm/email/email-send/EmailSend.vue'),
    meta: {
      resource: 'm_email_send',
      action: 'read',
    },
  },
  {
    path: '/emails/logs',
    name: 'email-logs',
    component: () => import('@/views/crm/email/email-log/EmailLogList.vue'),
    meta: {
      resource: 'm_email_send',
      action: 'read',
    },
  },
  {
    path: '/emails/logs/:id',
    name: 'email-log-details',
    component: () => import('@/views/crm/email/email-log/details/EmailLogDetail.vue'),
    meta: {
      resource: 'm_email_send',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Training ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/my-trainings',
    name: 'my-trainings',
    component: () => import('@/views/crm/training/my-trainings/MyTrainings.vue'),
    meta: {
      resource: 'my_training',
      action: 'read',
    },
  },
  {
    path: '/training-detail/:id',
    name: 'training-detail',
    component: () => import('@/views/crm/training/my-trainings/TrainingDetail.vue'),
    meta: {
      resource: 'my_training',
      action: 'read',
    },
  },
  {
    path: '/training-quiz/:training_history_id',
    name: 'training-quiz',
    component: () => import('@/views/crm/training/my-trainings/TrainingQuiz.vue'),
    meta: {
      resource: 'my_training',
      action: 'read',
    },
  },
  {
    path: '/training/list',
    name: 'training-list',
    component: () => import('@/views/crm/training/training-list/TrainingList.vue'),
    meta: {
      resource: 'm_training',
      action: 'read',
    },
  },
  {
    path: '/training/category',
    name: 'training-category',
    component: () => import('@/views/crm/training/training-category/TrainingCategoryList.vue'),
    meta: {
      resource: 'm_training',
      action: 'read',
    },
  },
  {
    path: '/training/edit/:id',
    name: 'training-edit',
    component: () => import('@/views/crm/training/training-edit/TrainingEdit.vue'),
    meta: {
      resource: 'training_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // Settings & roles
  {
    path: '/setting/roles',
    name: 'settings-roles',
    component: () => import('@/views/crm/role/RoleList.vue'),
    meta: {
      resource: 'm_config',
      action: 'read',
    },
  },
  // *===============================================---*
  // Monitoring
  {
    path: '/monitoring',
    name: 'monitoring',
    component: () => import('@/views/crm/monitoring/Monitoring.vue'),
    meta: {
      resource: 'm_order',
      action: 'read',
    },
  },
  // *===============================================---*
  // Reports
  {
    path: '/report/sales',
    name: 'report-sales',
    component: () => import('@/views/crm/reports/sales-reports/SalesReports.vue'),
    meta: {
      resource: 'm_report_sales',
      action: 'read',
    },
  },
  {
    path: '/report/earning-expense',
    name: 'earning-expense',
    component: () => import('@/views/crm/reports/sales-reports/EarningExpenseReports.vue'),
    meta: {
      resource: 'm_report_sales',
      action: 'read',
    },
  },
  {
    path: '/report/non-processed',
    name: 'non-processed',
    component: () => import('@/views/crm/reports/non-processed-reports/Reports.vue'),
    meta: {
      resource: 'm_report_sales',
      action: 'read',
    },
  },
  {
    path: '/report/stock-reports',
    name: 'stock-reports',
    component: () => import('@/views/crm/reports/stock-reports/Reports.vue'),
    meta: {
      resource: 'm_report_sales',
      action: 'read',
    },
  },
  {
    path: '/report/stock-reports-date-based',
    name: 'stock-reports-date-based',
    component: () => import('@/views/crm/reports/stock-reports-date-based/Reports.vue'),
    meta: {
      resource: 'm_report_sales',
      action: 'read',
    },
  },
  {
    path: '/report/targets',
    name: 'report-targets',
    component: () => import('@/views/crm/reports/target-reports/TargetReports.vue'),
    meta: {
      resource: 'm_report_sales',
      action: 'read',
    },
  },
  {
    path: '/report/training',
    name: 'report-trainings',
    component: () => import('@/views/crm/reports/training-reports/TrainingReports.vue'),
    meta: {
      resource: 'm_training_report',
      action: 'read',
    },
  },
  {
    path: '/report/debt',
    name: 'report-debt',
    component: () => import('@/views/crm/reports/debt-reports/DebtReports.vue'),
    meta: {
      resource: 'm_report_debt',
      action: 'read',
    },
  },
  // *===============================================---*
  // Technical Service
  // *===============================================---*
  {
    path: '/technical-service/list',
    name: 'technical-service',
    component: () => import('@/views/crm/technical-service/TechnicalServiceList.vue'),
    meta: {
      resource: 'm_technical_service',
      action: 'read',
    },
  },
  // *============================
  // Notifications
  // *===========================
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/crm/notification/NotificationPage.vue'),
  },
  {
    path: '/notifications/:id',
    name: 'notification-detail',
    component: () => import('@/views/crm/notification/NotificationDetail.vue'),
  },
  {
    path: '/notifications/add',
    name: 'notifications-add',
    component: () => import('@/views/crm/notification/NotificationAdd.vue'),
    meta: {
      resource: 'notification_send',
      action: 'read',
    },
  },
  // *============================
  // Contents
  // *===========================

  {
    path: '/content/list',
    name: 'content-list',
    component: () => import('@/views/crm/blog-content/content-list/ContentList.vue'),
    meta: {
      resource: 'blog_content_edit',
      action: 'read',
    },
  },
  {
    path: '/content/add',
    name: 'content-add',
    component: () => import('@/views/crm/blog-content/content-edit/ContentEdit.vue'),
    meta: {
      resource: 'blog_content_edit',
      action: 'read',
    },
  },
  {
    path: '/content/edit/:id',
    name: 'content-edit',
    component: () => import('@/views/crm/blog-content/content-edit/ContentEdit.vue'),
    meta: {
      resource: 'blog_content_edit',
      action: 'read',
    },
  },
  {
    path: '/content/show-list/:content_type',
    name: 'content-show-list',
    component: () => import('@/views/crm/blog-content/content-view/ContentViewList.vue'),
    meta: {
      resource: '*',
      action: 'read',
    },
  },
  {
    path: '/content/show/:id',
    name: 'content-show',
    component: () => import('@/views/crm/blog-content/content-view/ContentView.vue'),
    meta: {
      resource: '*',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/crm/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/crm/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/crm/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },
  // *===============================================---*
  // *--------- Documents ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/views/crm/file-manager/FileManager.vue'),
    meta: {
      resource: '*',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Activity ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/crm/activity/Calendar.vue'),
    meta: {
      resource: '*',
      action: 'read',
    },
  },
  {
    path: '/my-activity',
    name: 'my-activity',
    component: () => import('@/views/crm/activity/my-activity/MyActivities.vue'),
    meta: {
      resource: '*',
      action: 'read',
    },
  },
  {
    path: '/activity-types',
    name: 'activity-types',
    component: () => import('@/views/crm/activity/activity-types/ActivityTypeList.vue'),
    meta: {
      resource: 'm_activity_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- P_PRODUCTD ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/p-products/list',
    name: 'p-products-list',
    component: () => import('@/views/crm/p_product/products-list/ProductList.vue'),
    meta: {
      resource: 'm_import',
      action: 'read',
    },
  },
  {
    path: '/p-products/edit/:id',
    name: 'p-products-edit',
    component: () => import('@/views/crm/p_product/products-edit/ProductsEdit.vue'),
    meta: {
      resource: 'product_edit',
      action: 'read',
    },
  },

  {
    path: '/general-expense/list',
    name: 'general-expense-list',
    component: () => import('@/views/crm/p_general/products-list/ProductList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/marketing-expense/list',
    name: 'marketing-expense-list',
    component: () => import('@/views/crm/p_marketing/products-list/ProductList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/other-expense/list',
    name: 'other-expense-list',
    component: () => import('@/views/crm/p_other/products-list/ProductList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/p-sales/list',
    name: 'p-sales-list',
    component: () => import('@/views/crm/p_sales/products-list/ProductList.vue'),
    meta: {
      resource: 'm_import',
      action: 'read',
    },
  },
]
