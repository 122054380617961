// eslint-disable-next-line import/no-extraneous-dependencies
import { Turkish } from 'flatpickr/dist/l10n/tr'

export default {
  flatPickrConfig: {
    enableTime: true, dateFormat: 'Y-m-d H:i', locale: Turkish, altFormat: 'j F Y l H:i', altInput: true,
  },
  locales: [
    {
      locale: 'tr',
      // eslint-disable-next-line global-require
      img: require('@/assets/images/flags/tr.png'),
      name: 'Türkçe',
    },
    {
      locale: 'en',
      // eslint-disable-next-line global-require
      img: require('@/assets/images/flags/en.png'),
      name: 'English',
    },

  ],
  monthNames: [
    { number: 1, name: 'Ocak' },
    { number: 2, name: 'Şubat' },
    { number: 3, name: 'Mart' },
    {
      number: 4,
      name: 'Nisan',
    },
    { number: 5, name: 'Mayıs' },
    { number: 6, name: 'Haziran' },
    { number: 7, name: 'Temmuz' },
    { number: 8, name: 'Ağustos' },
    { number: 9, name: 'Eylül' },
    { number: 10, name: 'Ekim' },
    { number: 11, name: 'Kasım' },

    { number: 12, name: 'Aralık' },
  ],
  blogContentTypes: [
    {
      value: 1, label: 'Bilgilendirme Notu', page_title: 'Bilgilendirme Notları', key_name: 'notes',
    },
    {
      value: 2, label: 'Kampanya', page_title: 'Kampanyalar', key_name: 'campaigns',
    },
  ],
  notificationWarningTypes: [
    {
      value: 1, label: 'Bilgilendirme', color: 'info', icon: 'InfoIcon',
    },
    {
      value: 2, label: 'Başarılı İşlem Bildirimi', color: 'success', icon: 'ThumbsUpIcon',
    },
    {
      value: 3, label: 'Uyarı', color: 'warning', icon: 'AlertTriangleIcon',
    },
    {
      value: 4, label: 'Çok Önemli', color: 'danger', icon: 'AlertOctagonIcon',
    }],
  getBlogContentType(value, searchType = 'value') {
    let selectedType = null
    this.blogContentTypes.forEach(val => {
      if (searchType === 'value') {
        if (value === val.value) {
          selectedType = val
        }
      } else if (searchType === 'key') {
        if (value === val.key_name) {
          selectedType = val
        }
      }
    })
    return selectedType
  },
  getNotificationType(value) {
    let selectedType = null
    this.notificationWarningTypes.forEach(val => {
      if (value === val.value) {
        selectedType = val
      }
    })
    return selectedType
  },
  buildNotificationLink(item) {
    let link = null
    if (item.type === 1) {
      link = { name: 'notification-detail', params: { id: item.id } }
    }
    return link
  },
  getMonthName(monthNumber) {
    let selectedMonthName = ''
    this.monthNames.forEach(val => {
      if (monthNumber === val.number) {
        selectedMonthName = val.name
      }
    })
    return selectedMonthName
  },
  // bytes cinsinden dosya boyutunu okunaklı hale getir
  toHumanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      // eslint-disable-next-line no-param-reassign
      bytes /= thresh
      // eslint-disable-next-line no-plusplus
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return `${bytes.toFixed(dp)} ${units[u]}`
  },
  // date operations
  // currency formatter
  formatCurrency(price, digits = 2) {
    const dollarUSLocale = Intl.NumberFormat('tr-TR', { minimumFractionDigits: digits })
    return dollarUSLocale.format(price)
  },
  getGeneralYearsAndMonthList() {
    const d = new Date()
    const thisYear = d.getFullYear()
    const targetYear = thisYear - 2

    const yearMonthList = []

    // eslint-disable-next-line no-plusplus
    for (let yIndex = thisYear + 1; yIndex > targetYear; yIndex--) {
      // eslint-disable-next-line no-plusplus
      for (let mIndex = 12; mIndex > 1; mIndex--) {
        const obj = {
          year: yIndex,
          month: mIndex,
        }
        yearMonthList.push(obj)
      }
    }

    return yearMonthList
  },
}
